<template lang="pug">
head-meta(title-page="Мои заказы" )
user-menu(block-name="Мои заказы")
the-user-orders
</template>

<script>
import UserMenu from '../../components/_user/UserMenu'
import TheUserOrders from '../../components/user-orders/TheUserOrders'
import HeadMeta from '../../components/_layout/HeadMeta'

export default {
  name: 'UserOrdersPage',
  components: { HeadMeta, UserMenu, TheUserOrders }
}
</script>
