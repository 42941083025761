<template lang="pug">
aside.user-menu(v-if="user")
  .user-menu__cont.cont
    h1.user-menu__caption.caption {{ blockName }}
    ul.user-menu__list(v-if="user.is_auth")
      li.user-menu__item(v-for="(link, i) in links", :key="i")
        router-link.user-menu__button(
            :to="{ name: link.pathName }",
            exact-active-class="user-menu__button_active",
            exact=true
          ) {{ link.text }}
</template>

<script>
import '@/assets/styles/components/user-menu.sass'
import { getUser } from '../../assets/scripts/API'

export default {
  props: {
    blockName: String
  },
  name: 'UserMenu',
  data () {
    return {
      user: null,
      links: [
        { pathName: 'UserOrders', text: 'Мои заказы' },
        { pathName: 'Basket', text: 'Корзина' },
        { pathName: 'Favorites', text: 'Избранное' },
        { pathName: 'UserInfo', text: 'Личные данные' },
        { pathName: 'UserLogout', text: 'Выйти из кабинета' }
      ]
    }
  },
  async mounted () {
    this.user = await getUser()
  }
}
</script>
