<template lang="pug">
.user-orders
  .user-orders__cont.cont
    .user-orders__list(v-if="orders.length")
      .user-orders__item(v-for="order in orders", :key="order.id")
        .user-orders__head
          span.user-orders__number.headline Заказ №{{ order.id }}
          span.user-orders__status(:class="`user-orders__status_${ order.status }`") {{ getStatus(order.status) }}
          .user-orders__head-row
            .user-orders__head-box
              span.user-orders__head-name.text Дата оформления
              span.user-orders__head-value.text {{ getDate(order.created_at) }}
            .user-orders__head-box
              span.user-orders__head-name.text Способ доставки
              span.user-orders__head-value.text {{ order.delivery }}
            .user-orders__head-box
              span.user-orders__head-name.text Кто сделал заказ
              span.user-orders__head-value.text
                | {{ order.name }}
                |
                span.user-orders__head-phone {{ order.phone }}
            //.user-orders__head-box
              button.user-orders__head-button.button-dark(type="button") Вопрос по заказу
        .user-orders__body
          .user-orders__product(v-for="product in order.products", :key="product.id")
            picture.user-orders__picture
              source(:srcset="`${product.images.w40} 1x, ${product.images.w80} 2x`", type="image/webp", media="(max-width: 520px)")
              source(:srcset="`${product.images.w80} 1x, ${product.images.w120} 1.5x`", type="image/webp")
              source(:srcset="`${product.images.j80} 1x, ${product.images.j120} 1.5x`")
              img.user-orders__image(:src="product.images.j80", :alt="`${product.name} - фото`", :title="`${product.name} - фото`", loading="lazy", width="80", height="80")
            .user-orders__info
              router-link.user-orders__name.link_white-transparent(:to="{ name: 'Product', params: { slug: product.slug }}") {{ product.name }}
              span.user-orders__offer.text(v-if="product.typeName") {{ product.typeName }}
              span.user-orders__count.text Количество: {{ product.quantity }} шт.
              span.user-orders__comment.text(v-if="product.comment") Пожелания: {{ product.comment }}
        //.order-card__bot
          button.order-card__bot-button.button-white(type="button") Повторить заказ
          p.order-card__bot-text.text Хотите повторить заказ? Нажмите на кнопку, и товары из этого заказа будут ждать Вас в корзине
    .user-orders__lid(v-else)
      p.user-orders__text.text-big Здесь пока пусто. Пора заказать что-нибудь!
      router-link.user-orders__link.link-more(:to="{ name: 'Catalog' }") В каталог
</template>

<script>
import '@/assets/styles/components/user-orders.sass'
import { getBasketHistory } from '../../assets/scripts/API'

export default {
  name: 'TheUserOrders',
  data () {
    return {
      orders: []
    }
  },
  methods: {
    getStatus (statusId) {
      if (statusId === 2) return 'Оплачен'
      if (statusId === 3) return 'Отменен'
      return 'Обрабатывается'
    },
    getDate (date) {
      const options = { year: 'numeric', month: 'long', day: '2-digit', hour: '2-digit', minute: '2-digit' }
      return new Intl.DateTimeFormat('ru-RU', options).format(date)
    },
    async getOrders () {
      const res = await getBasketHistory()
      return res.orders || []
    }
  },
  async mounted () {
    this.orders = await this.getOrders()
  }
}
</script>
